<template>
  <svg width="90" height="112" viewBox="0 0 90 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M45 112C67.3999 112 89.7999 69.5424 89.7999 44.8C89.7999 20.0576 69.7424 0 45 0C20.2575 0 0.199951 20.0576 0.199951 44.8C0.199951 69.5424 22.6 112 45 112Z" fill="url(#paint0_linear_648_548)"/>
    <path d="M44.8833 76C50.9055 76 56.5533 74.0413 61.4092 70.6278C63.0624 69.4656 63.5056 66.8338 62.3661 64.9853L62.325 64.9188C61.3386 63.3186 59.5112 62.8956 58.0779 63.8971C54.2004 66.6059 49.6931 68.158 44.8833 68.158C36.2815 68.158 28.6466 63.1951 23.8547 55.5259C22.8737 53.9557 21.0551 53.543 19.6477 54.5423C19.6283 54.5559 19.6089 54.5698 19.5897 54.5835C17.9445 55.7515 17.489 58.3813 18.6365 60.2161C24.6255 69.7927 34.1612 76 44.8833 76Z" fill="url(#paint1_linear_648_548)"/>
    <g filter="url(#filter0_d_648_548)">
    <path d="M45 68.6592C58.1769 68.6592 68.8589 57.9772 68.8589 44.8003C68.8589 31.6234 58.1769 20.9414 45 20.9414C31.8231 20.9414 21.1411 31.6234 21.1411 44.8003C21.1411 57.9772 31.8231 68.6592 45 68.6592Z" fill="url(#paint2_linear_648_548)"/>
    </g>
    <path d="M25.3919 95.9165C27.2323 95.9165 28.7242 94.7175 28.7242 93.2385C28.7242 91.7595 27.2323 90.5605 25.3919 90.5605C23.5515 90.5605 22.0596 91.7595 22.0596 93.2385C22.0596 94.7175 23.5515 95.9165 25.3919 95.9165Z" fill="#EFB1D2"/>
    <path d="M64.608 95.9165C66.4484 95.9165 67.9403 94.7175 67.9403 93.2385C67.9403 91.7595 66.4484 90.5605 64.608 90.5605C62.7676 90.5605 61.2756 91.7595 61.2756 93.2385C61.2756 94.7175 62.7676 95.9165 64.608 95.9165Z" fill="#EFB1D2"/>
    <path d="M31.1051 93.2375C34.7546 93.2375 37.7131 90.279 37.7131 86.6295C37.7131 82.98 34.7546 80.0215 31.1051 80.0215C27.4556 80.0215 24.4971 82.98 24.4971 86.6295C24.4971 90.279 27.4556 93.2375 31.1051 93.2375Z" fill="white"/>
    <path d="M58.8949 93.2375C62.5444 93.2375 65.5029 90.279 65.5029 86.6295C65.5029 82.98 62.5444 80.0215 58.8949 80.0215C55.2454 80.0215 52.2869 82.98 52.2869 86.6295C52.2869 90.279 55.2454 93.2375 58.8949 93.2375Z" fill="white"/>
    <path d="M31.105 90.568C33.2801 90.568 35.0433 88.8048 35.0433 86.6297C35.0433 84.4546 33.2801 82.6914 31.105 82.6914C28.93 82.6914 27.1667 84.4546 27.1667 86.6297C27.1667 88.8048 28.93 90.568 31.105 90.568Z" fill="#353544"/>
    <path d="M58.8948 90.568C61.0699 90.568 62.8331 88.8048 62.8331 86.6297C62.8331 84.4546 61.0699 82.6914 58.8948 82.6914C56.7198 82.6914 54.9565 84.4546 54.9565 86.6297C54.9565 88.8048 56.7198 90.568 58.8948 90.568Z" fill="#353544"/>
    <path d="M45 93.4475C43.8291 93.4475 42.6584 93.0049 41.7033 92.1192C41.2515 91.7002 41.2248 90.9945 41.6438 90.5427C42.0628 90.0909 42.7684 90.0641 43.2202 90.4831C44.2682 91.4545 45.7315 91.4545 46.7797 90.4831C47.231 90.0643 47.9372 90.0907 48.3559 90.5427C48.7749 90.9945 48.7484 91.7002 48.2964 92.1192C47.3415 93.0047 46.1706 93.4475 45 93.4475Z" fill="#353544"/>
    <defs>
    <filter id="filter0_d_648_548" x="17.1411" y="20.9414" width="55.7178" height="55.7188" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_648_548"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_648_548" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_648_548" x1="80.674" y1="-3.90229e-06" x2="-15.3896" y2="15.5228" gradientUnits="userSpaceOnUse">
    <stop stop-color="#F98419"/>
    <stop offset="1" stop-color="#FF9C41"/>
    </linearGradient>
    <linearGradient id="paint1_linear_648_548" x1="58.4167" y1="54" x2="16.1289" y2="71.4713" gradientUnits="userSpaceOnUse">
    <stop stop-color="#F98419"/>
    <stop offset="1" stop-color="#FF9C41"/>
    </linearGradient>
    <linearGradient id="paint2_linear_648_548" x1="45" y1="64.4503" x2="45" y2="18.0319" gradientUnits="userSpaceOnUse">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="#FFD8D8"/>
    </linearGradient>
    </defs>
  </svg>
</template>